import useStrTypography from "@/hooks/useStrTypography";
import { storeColorCss } from "@/services/ceSettings/ceSettingsService";
import { ContentElementFormStoreSetting } from "@/types/ceSettings/ceSettings";
import { CEForm } from "@/types/content-elements";
import { ThemeProvider, createTheme } from "@mui/material";
import { PropsWithChildren } from "react";

interface FormThemeProviderProps {
  ceSettings: ContentElementFormStoreSetting | undefined;
  content: CEForm;
}

/**
 * MUI theme for form input elements
 */
export default function FormThemeProvider(
  props: PropsWithChildren<FormThemeProviderProps>
) {
  const textFieldBorder =
    props.ceSettings?.inputBorder?.replace("border:", "") ?? "none";
  const textFieldBorderHover =
    props.ceSettings?.inputBorderHover?.replace("border:", "") ?? "none";
  const { typographyClassName: textFieldClassName } = useStrTypography(
    props.content?.cfgStrInnerTypographyInputTypography,
    props.ceSettings?.inputTypography
  );
  const textFieldBackgroundColor = storeColorCss(
    props.ceSettings?.inputBgColor,
    "var(--pb-white)"
  );

  const theme = createTheme({
    components: {
      MuiTextField: {
        defaultProps: {
          variant: "outlined",
          fullWidth: true,
          className: textFieldClassName,
        },
        styleOverrides: {
          root: {
            marginBottom: "1rem",
            ".MuiInputBase-root": {
              paddingTop: "0px !important",
              paddingBottom: "0px !important",
              paddingLeft: "0px !important",
              backgroundColor: textFieldBackgroundColor,
              borderRadius: "var(--pb-rounded-input-border-radius) !important", // see globalStyle.tsx
              border: textFieldBorder,
              color: "inherit",
              fontSize: "inherit",
              fontFamily: "inherit",
              fontWeight: "inherit",
              height: "unset !important", // see globalStyle.tsx
              lineHeight: "inherit",
              letterSpacing: "inherit",
              textTransform: "inherit",
              "&:hover, &.Mui-focused": {
                border: textFieldBorderHover,
                ".MuiOutlinedInput-notchedOutline": {
                  border: "0px !important", // see globalStyle.tsx
                },
              },
            },
            ".MuiInputBase-input": {
              padding: "6px !important", // see globalStyle.tsx
              color: "inherit",
              fontSize: "inherit",
              fontFamily: "inherit",
              fontWeight: "inherit",
              lineHeight: "inherit",
              letterSpacing: "inherit",
              textTransform: "inherit",
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: "0px !important", // see globalStyle.tsx
              borderRadius: "5px",
              overflow: "hidden !important", // see globalStyle.tsx
              "&::after": {
                border: "none",
              },
            },
            ".MuiSvgIcon-root, .MuiButtonBase-root": {
              fontSize: "inherit",
              fill: `var(--pb-typography-color) !important`, // see globalStyle.tsx
            },
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          className: textFieldClassName,
        },
        styleOverrides: {
          root: {
            color: "var(--pb-typography-color) !important",
            ".MuiSvgIcon-root": {
              fontSize: "30px",
            },
          },
        },
      },
      MuiPopper: {
        styleOverrides: {
          root: {
            ".MuiDayCalendar-weekDayLabel, .MuiPickersDay-root, .MuiPickersCalendarHeader-label, .MuiPickersYear-yearButton":
              {
                fontSize: "12px",
              },
          },
        },
      },
    },
  });

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
