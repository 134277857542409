
import { FORM_CE_NAME, FormProps } from "./formContentElement";

/**
 * This is the global style of a CE. 
 * This style is applied on all instances of this CE
 * 
 * @param props 
 * @returns 
 */
export default function FormContentElementStyleGlobal(props: FormProps) {
  return (
    <style jsx global>
      {`
        :global(.${FORM_CE_NAME}) {
          .cms-form .MuiAutocomplete-endAdornment svg {
            fill: var(--pb-cms-color);
          }
          .cms-form .cms-input-label {
            color: var(--pb-cms-color);
          }
          .cms-form #cms-autocomplete-id-select-form::placeholder,
          .cms-button-input::placeholder {
            color: var(--pb-cms-color);
            opacity: 0.85 !important;
          }
          .cms-form .cms-info-text {
            color: var(--pb-cms-color);
          }
        }
      `}
    </style>
  );
}
