import Captcha from "@/components/captcha/captcha";
import PbContentButton from "@/components/content/pbContentButton/pbContentButton";
import PbContentHeading from "@/components/content/pbContentHeading/pbContentHeading";
import PbAutoComplete from "@/components/input/pbAutoComplete/pbAutoComplete";
import FormThemeProvider from "@/components/themeProviders/FormThemeProvider";
import useCesStrButton from "@/hooks/useCesStrButton";
import useCesStrHeadline from "@/hooks/useCesStrHeadline";
import useCmsTranslation from "@/hooks/useCmsTranslation";
import {
  checkCaptcha,
  isCaptchaActivated,
} from "@/services/captchaService/captchaService";
import { getFormsRequest } from "@/services/cmsFormService/cmsFormService";
import { submitForm } from "@/services/form/formService";
import { updateAttributeAction } from "@/store/slices/cmsEdit/cmsEditSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { ContentElementFormStoreSetting } from "@/types/ceSettings/ceSettings";
import { CEForm } from "@/types/content-elements";
import { PbForm } from "@/types/pbForm/pbForm";
import { deepImmutableCopy } from "@/utils/util";
import { TextField } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CmsCaptchaPlaceholder from "../cmsCaptchaPlaceholder/cmsCaptchaPlaceholder";
import CmsFormFieldRenderer from "./cmsFormFieldRenderer";
import CmsFormStyle from "./cmsFormStyle";
import CmsInputLabel from "./cmsInputLabel/cmsInputLabel";

interface CmsFormProps {
  form: PbForm | null;
  position: number;
  ceSettings?: ContentElementFormStoreSetting;
  disableEditView?: boolean;
  content: CEForm;
}

const CmsForm = (props: CmsFormProps) => {
  const tCms = useCmsTranslation();
  const dispatch = useAppDispatch();
  const { t: tPublic } = useTranslation("public");
  const [isSubmittable, setIsSubmittable] = useState(false);
  const [formData, setFormData] = useState<any>([]);
  const [submitSuccessful, setSubmitSuccessful] = useState<boolean | null>(
    null
  );
  const editMode = useAppSelector((state) => state.cmsGeneral.editMode);
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const [cmsFormsSelectList, setCmsFormsSelectList] = useState<any>([]);
  const [captchaResponse, setCaptchaResponse] = useState<string | null>(null);

  const headlineSettings = useCesStrHeadline({
    cfgHeadlineType: props.ceSettings?.headlineheading ?? "default",
  });

  const { buttonClassName: submitButtonClassname } = useCesStrButton(
    props.content.cfgStrInnerButtonSubmitButton,
    props.ceSettings?.submitButton
  );

  const { buttonClassName: backButtonClassname } = useCesStrButton(
    props.content.cfgStrInnerButtonBackButton,
    props.ceSettings?.backButton
  );

  const submit = async (e: any) => {
    e.preventDefault();

    const captchaMandatory = props.form?.cfgWithCaptcha ? true : false;
    const validatedCaptchaResponse = checkCaptcha();

    if (
      (captchaMandatory && validatedCaptchaResponse) ||
      !captchaMandatory ||
      !isCaptchaActivated()
    ) {
      // RequestSize:
      global.log.debug(
        JSON.stringify({
          formId: props.form?.id,
          data: formData,
          captchaResponse: validatedCaptchaResponse,
        }).length /
          1024 /
          1024
      );
      if (editMode) {
        global.log.debug("[editMode] submit form:");
        global.log.debug({
          formId: props.form?.id,
          data: formData,
          captchaResponse: validatedCaptchaResponse,
        });
      }

      const submitRequest = await submitForm({
        formId: props.form?.id,
        data: formData,
        captchaResponse: validatedCaptchaResponse,

        ...(props.form?.cfgWithHP &&
          (
            document.querySelector(
              'input[name="websiteUrl"]'
            ) as HTMLInputElement
          ).value !== "" && {
            hpTriggered: true,
          }),
      });

      if (submitRequest.success) {
        setSubmitSuccessful(true);
      } else {
        setSubmitSuccessful(false);
      }
      // Reset captcha response after submit
      setCaptchaResponse(null);
    } else if (captchaMandatory && !validatedCaptchaResponse) {
      setCaptchaResponse("");
    }
  };

  const updateFormData = (validatedFieldData: any) => {
    const previousFormData: any = formData;
    previousFormData[validatedFieldData.formPos] = {
      ...previousFormData[validatedFieldData.formPos],
      fieldValue: validatedFieldData.value,
      isValid: validatedFieldData.isValid,
    };

    setFormData(deepImmutableCopy(previousFormData));
  };

  const resetForm = () => {
    setIsSubmittable(false);
    setSubmitSuccessful(null);
    setCaptchaResponse(null);
    setFormData([]);
  };

  const onFormSelectChange = (value: any) => {
    if (value !== "") {
      dispatch(
        updateAttributeAction({
          attributePath: `draftPage.content[${props.position}].cfgSelectFormId`,
          value: parseInt(value, 10),
        })
      );
    }
  };

  // loads all forms to fill the dropdown
  useEffect(() => {
    if (editView && props.form === null) {
      (async () => {
        const formsResult = await getFormsRequest();

        const data = formsResult.success ? formsResult.data : [];
        const labelArr =
          data.length > 0
            ? data.map((form) => ({ label: form.name, id: form.id }))
            : [];
        setCmsFormsSelectList(labelArr);
      })();
    }
  }, [editView, props.form]);

  // Initial Loading of FormData
  useEffect(() => {
    if (formData.length < 1) {
      if (props.form && props.form.fields.length > 0) {
        // adding isValid to initial formData
        setFormData(
          props.form.fields.map((formField: any) => {
            return {
              ...formField,
              isValid:
                formField.__component === "formfields.checkbox" &&
                !formField.cfgFieldIsMandatory
                  ? true
                  : false,
            };
          })
        );
      }
    }
  }, [props.form, formData]);

  // Checking validity-State of formData
  useEffect(() => {
    if (formData.length > 0) {
      const allFieldsValid = formData.every((formField: any) => {
        if (formField?.cfgFieldIsMandatory && !formField.isValid) {
          return false;
        }
        if (formField?.fieldValue && !formField.isValid) {
          return false;
        }
        return true;
      });
      global.log.debug({ allFieldsValid });
      setIsSubmittable(allFieldsValid);
    }
  }, [formData]);

  return (
    <>
      <Row className="edge-to-edge-row">
        <Col xs={12} className="cms-form">
          {submitSuccessful ? (
            <div className="submit-successful">
              {props.ceSettings?.headlineEnabled && (
                <PbContentHeading
                  content={props.form?.title ?? ""}
                  cmsPosition={props.position}
                  cmsField="form.title"
                  settings={headlineSettings}
                  disableEditView
                  textAlign="center"
                />
              )}
              <br />
              <p>{tPublic("form-formSendingSuccessful")}</p>
              <br />
              <PbContentButton
                onClick={() => resetForm()}
                disableEditView
                className={`${backButtonClassname}`}
                text={props.form?.cfgFormBackButtonText ?? tPublic("back")}
              />
            </div>
          ) : editView && !props.disableEditView ? (
            props.form ? (
              props.form.fields.length > 0 ? (
                <form onSubmit={(e) => submit(e)}>
                  {props.ceSettings?.headlineEnabled && (
                    <PbContentHeading
                      content={props.form.title}
                      cmsPosition={props.position}
                      cmsField="form.title"
                      settings={headlineSettings}
                      disableEditView
                      textAlign="center"
                    />
                  )}
                  {props.form.fields.map((formField, index) => {
                    return (
                      <div
                        className={`form-field-wrapper`}
                        key={"" + props.form?.id + index}
                      >
                        <FormThemeProvider
                          ceSettings={props.ceSettings}
                          content={props.content}
                        >
                          <CmsFormFieldRenderer
                            content={props.content}
                            formField={formField}
                            index={index}
                            isEditor
                            updateFormData={updateFormData}
                            ceSettings={props.ceSettings}
                          />
                        </FormThemeProvider>
                        {/* Allows styling of the placeholders accordingly to the typography */}
                        <style jsx global>
                          {`
                            .form-field-renderer
                              .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled {
                              color: inherit;
                            }
                            .form-field-renderer
                              .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
                              -webkit-text-fill-color: unset;
                            }
                          `}
                        </style>
                      </div>
                    );
                  })}
                  {props.form.cfgWithCaptcha ? <CmsCaptchaPlaceholder /> : null}
                  <Row className="edge-to-edge-row">
                    <Col xs={12} style={{ textAlign: "center" }}>
                      <PbContentButton
                        type="submit"
                        disableEditView
                        disabled={!isSubmittable}
                        className={`mx-2 ${submitButtonClassname}`}
                        text={
                          props.form.cfgFormSubmitButtonText || tPublic("send")
                        }
                      />
                    </Col>
                  </Row>
                </form>
              ) : (
                <>
                  {/* If the formId could not be fetched or the 
                          form has no form fields. */}
                  {props.form && props.form.fields.length === 0 ? (
                    <p className="text-center cms-info-text">
                      {tCms("form-noFormFieldsInForm")}
                    </p>
                  ) : (
                    <p className="text-center cms-info-text">
                      {tPublic("form-couldNotLoadForm")}
                    </p>
                  )}
                </>
              )
            ) : (
              <>
                <Row className="edge-to-edge-row justify-content-center">
                  <Col className="col-auto px-1">
                    {/* If the formId is 0. */}
                    <PbAutoComplete
                      withCmsStyle
                      label={tCms("form-selectForm")}
                      options={cmsFormsSelectList}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            style: {
                              color: "#fff",
                            },
                          }}
                          placeholder={tCms("form-selectForm")}
                          margin="none"
                        />
                      )}
                      onChange={(e, value) => {
                        if (value) {
                          onFormSelectChange(value.id);
                        }
                      }}
                      getOptionLabel={(option) => `${option.label}`}
                      id="-select-form"
                    />
                  </Col>
                </Row>
              </>
            )
          ) : props.form ? (
            props.form.fields.length > 0 ? (
              <form onSubmit={(e) => submit(e)}>
                {props.ceSettings?.headlineEnabled && (
                  <PbContentHeading
                    content={props.form.title}
                    cmsPosition={props.position}
                    cmsField="form.title"
                    settings={headlineSettings}
                    disableEditView
                    textAlign="center"
                  />
                )}
                {props.form.fields.map((formField, index) => {
                  return (
                    <div
                      className={`form-field-wrapper`}
                      key={"" + props.form?.id + index}
                    >
                      <FormThemeProvider
                        ceSettings={props.ceSettings}
                        content={props.content}
                      >
                        <CmsFormFieldRenderer
                          formField={formField}
                          index={index}
                          isEditor={false}
                          updateFormData={updateFormData}
                          content={props.content}
                          ceSettings={props.ceSettings}
                        />
                      </FormThemeProvider>
                    </div>
                  );
                })}
                {props.form.cfgWithCaptcha ? (
                  <Row className="edge-to-edge-row">
                    <Col xs={12}>
                      <div className="py-2">
                        <Captcha showHint={captchaResponse === ""} />
                      </div>
                    </Col>
                  </Row>
                ) : null}

                {props.form.cfgWithHP ? (
                  <>
                    <Row>
                      <Col xs={12}>
                        <div className="py-2 url-field-wrapper">
                          <CmsInputLabel
                            label={tPublic("form-websiteUrlLabel")}
                          />
                          <input
                            type="text"
                            name="websiteUrl"
                            // tabIndex="-1"
                            //value="DDD"
                            autoComplete="off"
                            aria-label={tPublic("form-websiteUrlDescription")}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : null}

                <Row className="edge-to-edge-row">
                  <Col xs={12} style={{ textAlign: "center" }}>
                    <PbContentButton
                      type="submit"
                      disableEditView
                      disabled={!isSubmittable}
                      className={`mx-2 ${submitButtonClassname}`}
                      text={
                        props.form.cfgFormSubmitButtonText || tPublic("send")
                      }
                    />
                  </Col>
                </Row>
              </form>
            ) : (
              <>
                {/* If the formId could not be fetched. show nothing to the user */}
              </>
            )
          ) : (
            <>{/* If the formId is 0. */}</>
          )}
        </Col>
      </Row>
      <CmsFormStyle />
    </>
  );
};

export default CmsForm;
